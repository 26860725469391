// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-selection-container {
    text-align: center;
    padding: 20px;
}

.card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card h2 {
    margin-bottom: 10px;
}

.card p {
    color: #666;
}
`, "",{"version":3,"sources":["webpack://./src/SessionSelection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,wCAAwC;IACxC,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".session-selection-container {\n    text-align: center;\n    padding: 20px;\n}\n\n.card-container {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.card {\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    width: 250px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n    transition: box-shadow 0.3s ease;\n}\n\n.card:hover {\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.card h2 {\n    margin-bottom: 10px;\n}\n\n.card p {\n    color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
