// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agentperformance-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2d3748;
    padding: 20px;
    color: white;
    border-radius: 8px;
    margin-bottom: 20px;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logout-btn {
    padding: 8px 16px;
    background-color: #e53e3e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".agentperformance-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #2d3748;\n    padding: 20px;\n    color: white;\n    border-radius: 8px;\n    margin-bottom: 20px;\n}\n\n.user-info {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n}\n\n.logout-btn {\n    padding: 8px 16px;\n    background-color: #e53e3e;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
